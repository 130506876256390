@import "../../Styles/variables";

.form-name {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 700;

    img{
        margin: 20px;
        width: 100px;
        height: 100px;
    }

    p{
        width: 60%;

        text-align: center;
        font-size: 18px;
        line-height: 130%;
    }

    .instruction {
        font-size: 16px;
        color: $orange;
        text-decoration: none;

        @media screen and (max-height: 530px) {
            display: none;
        }
    }

    .form-text {
        margin: 60px 10%;
        @media screen and (max-width: 400px) {
            margin: 40px 10%;
        }
    }
}