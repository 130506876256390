@import 'variables';

.form-text{
    margin: 80px 10%;
    -webkit-appearance: none;

    .input-text {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-bottom: 36px;

        width: 100%;
        box-sizing: border-box;

        border: none;
        border-bottom: 2px solid $orange;
        border-radius: 0;

        text-align: center;
        font-size: 36px;
        line-height: 150%;
        color: $orange;

        &-invalid {
            color: $orange-clear;
        }
    }
}