@import 'variables';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80%;
    max-width: 500px;
    height: 250px;
    border-radius: 28px;

    background-color: $orange;

    color: $white;
    text-align: center;
    font-family: 'Nunito';
    font-weight: 400;

    h4{
        margin: 15px 20px 5px 20px;
        font-size: 14px;
    }

    p{
        margin: 0px 30px 0px 30px;
        font-size: 12px;

        &:nth-of-type(2){
            font-style: italic;
        }
    }

    .btn-container {
        position: absolute;
        left: 7%;
        right: 7%;
        bottom: 10%;

        display: flex;
        justify-content: space-between;

        button {
            max-width: 40%;
            padding: 9px 10%;
        }
    }
}