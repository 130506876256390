@import "../Styles/variables";

.landing{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $orange;

    .logo, .title{
        width: 60%;
        max-width: 300px;
    }

    .title {
        margin-bottom: 50px;
    }

    .subtitle {
        border-bottom: 2px solid white;
        padding: 0;
    }

    .link {
        position: absolute;
        bottom: 5%;
        text-align: center;
        font-size: 14px;

        p {
            margin: 0;
            color: $teal;
            font-weight: 700;
        }

        a {
            color: $white;
        }

        @media screen and (max-width: 768px) {
            bottom: 3%;
            font-size: 12px;
        }
    }
}