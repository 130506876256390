@import 'variables';

button {
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
        outline: none;
    }
}

.btn{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: none;
    border-radius: 44px;
    padding: 9px 36px;

    background-color: $grey;
    color: $trousers;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}

//Sizes
.btn-wide{
    width: 100%;
}

//Colors
.btn-white{
    background-color: $white;
    color: $trousers;
}

.btn-teal{
    background-color: $teal;
}

.btn-inactive{
    background-color: $grey;
    color: $off-black;
}

.btn-submit{
    background-color: $teal;
    color: $off-black2;
}

.btn-pink{
    background-color: $pink;
    color: $white;
}

.btn-border-teal{
    border: 2px solid $teal;
}
