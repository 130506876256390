.score {

    .buttons {
        margin: 0 10px;
    }

    form {
        .input-score {
            width: 30px;
        }
    }
}