@import "Styles/variables";
@import "Styles/buttons";

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Ultra&display=swap');

html, body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $white;

  font-family: 'Nunito', sans-serif;

  .hidden {
    display: none;
  }

  .transparent {
    opacity: 0;
  }
}

h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: $orange;
}