@import '../../../Styles/variables';

.leaderboard-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .leaderboard-title {
        position: fixed;
        top: 0;
        z-index: 100;

        width: 100%;
        height: 60px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $orange;
        color: white;

        h3 {
            margin: 0;
            font-weight: normal;
            font-size: 36px;
            text-align: center;
        }
    }

    .leaderboard {
        position: absolute;
        top: 60px;

        width: 100%;
    }

    .user-info {
        position: relative;
        left: 0;

        margin: 16px 0;
        width: 95%;
        max-height: 132px;
        
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 46px;

        border-radius: 0 12px 12px 0;
        box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

        &:last-of-type {
            margin-bottom: 45%;
        }

        .name {
            display: inline-block;
            width: 65%;
            padding-left: 20px;
            color: $trousers;
        }

        .score {
            position: absolute;
            top: 0;
            right: 0;

            width: 30%;
            height: 100%;

            padding-left: 5px;

            border-radius: 0 12px 12px 0;
            background-color: $teal;
        }
    }

    .button-container {
        position: fixed;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);

        width: 80%;
        max-width: 300px;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 30%;
            height: fit-content;

            padding: 0;

            img {
                width: 100%;
            }
        }

        .btn-control {
            -webkit-filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
        }

        .btn-leaderboard {
            img {
                width: 45%;
            }
        }
    }
}