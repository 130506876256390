.show-list {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .admin {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 2%;

        .show-new, .show-select {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 5%;

            .scrollable {
                max-height: 100px;
                overflow-y: scroll;
            }
        }
    }
}