.controls-show {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .show-active-toggle {
        width: 150px;
    }

    .list-users {
        width: 80%;
    }

    h1 {
        margin: 0;
    }
}