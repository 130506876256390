.card {
    margin-top: 5%;
    width: 200px;
    height: 300px;

    img {
        width: 100%;
        border-radius: 28px;
    }

    @media screen and (max-height: 500px) {
        height: 60%;
        display: flex;
        justify-content: center;

        img {
            width: auto;
            height: 150%;
        }
    }
}