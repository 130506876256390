.intro {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        margin: 20px 0 0 0;
        width: 100px;
        height: 100px;
        
        @media screen and (max-width: 360px){
            margin: 10px 0 0 0;
        }
    }

    h5{
        width: 70%;
        text-align: center;

        @media screen and (max-width: 360px){
            margin: 8px 0 16px 0;
        }
    }

    p{
        margin: 4px 0;
        width: 80%;

        &:last-of-type {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 360px){
            font-size: 14px;

            &:last-of-type {
                margin-bottom: 15px;
            }
        }

        @media screen and (max-height: 670px){
            font-size: 14px;
        }
    }

    a{
        width: 60%;
    }
}