@import '../../Styles/variables';

.backstage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        background-color: $teal;
        border: 1px solid black;
    }

    .btn-delete{
        background-color: red;
        color: white;
    }
}