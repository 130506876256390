.user-info-back {
    width: 100%;

    &:nth-child(2n + 0) {
        background-color: lightgrey;
    }

    .summary {
        display: flex;
        justify-content: space-between;

        .name {
            flex-basis: 20%;
            display: flex;
            align-items: flex-start;
        }

        .score {
            flex-basis: 60%;
            display: flex;
            align-items: flex-start;

            .score-score {
                width: 130px;
            }
        }
    }

    .answers {
        p {
            margin: 5px 0 5px 30px;
        }
    }
}