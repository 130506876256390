@import '../../../Styles/variables';

.simple-leaderboard-container {
    width: 100%;

    .leaderboard-title {
        z-index: 100;
        margin: 0;

        width: 100%;
        height: 60px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $orange;
        color: white;

        h3 {
            margin: 0;
            font-weight: normal;
            font-size: 36px;
            text-align: center;
        }
    }

    .leaderboard {
        width: 100%;

        &-fullscreen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            overflow-x: hidden;

            background-color: $white;

            .leaderboard-title{
                position: fixed;
                top: 0;
            }

            .user-container {
                margin-top: 60px;
            }
        }
    }

    .user-container {
        display: flex;
        flex-wrap: wrap;
    }

    .user-info {
        position: relative;
        left: 0;

        margin: 16px 5%;
        width: 40%;
        max-height: 132px;
        
        font-size: 24px;
        line-height: 46px;

        border-radius: 12px;
        box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

        .name {
            display: inline-block;
            width: 70%;
            padding-left: 20px;
            color: $trousers;
        }

        .score {
            position: absolute;
            top: 0;
            right: 0;

            width: 25%;
            height: 100%;

            padding-left: 5px;

            border-radius: 0 12px 12px 0;
            background-color: $teal;
            color: $white
        }
    }
}