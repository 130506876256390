@import "../Styles/variables";

.code-entry{
    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 700;

    img{
        margin: 20px;
        width: 100px;
        height: 100px;
    }

    p{
        width: 60%;

        text-align: center;
        font-size: 18px;
        line-height: 130%;

        @media screen and (max-width: 400px) {
            margin-bottom: 0;
        }
    }

    .note {
        position: absolute;
        bottom: 10%;

        font-size: 14px;
        color: $off-black;
        text-decoration: none;

        @media screen and (max-width: 400px) {
            bottom: 7%;
            font-size: 10px;
        }

        @media screen and (max-height: 670px) {
            bottom: 7%;
            font-size: 10px;
        }

        @media screen and (max-height: 530px) {
            display: none;
        }
    }

    .manager-link {
        position: absolute;
        bottom: 5%;

        font-size: 14px;
        color: $off-black;
        text-decoration: none;

        @media screen and (max-width: 400px) {
            bottom: 4%;
            font-size: 10px;
        }

        @media screen and (max-height: 530px) {
            display: none;
        }
    }
}