@import '../../../Styles/variables';

.swipe-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-warning {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url("../../../Images/bg-pattern.png");
        background-size: auto;

        .card-container {
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        h4 {
            margin: 0;
            width: 80%;
            max-width: 800px;
            text-align: center;
            font-family: 'Ultra';
            font-size: 24px;
            font-weight: 400;
            color: $teal;
            margin-bottom: 20px;
        }

        a {
            color: $white;

            &:visited {
                color: $trousers
            }
        }

        .btn-leaderboard {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100px;
        }
    }

    .card-container {
        position: relative;
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swipe {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .card {
            width: 80%;
        }
    }

    .button-container {
        width: 80%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 30%;
            height: fit-content;

            padding: 0;

            img {
                width: 100%;
            }
        }

        .btn-choice {
            -webkit-filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
        }

        .btn-reject {
            
        }

        .btn-leaderboard {
            img {
                width: 45%;
            }
        }

        .btn-accept {

        }
    }
}

.waiting-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url("../../../Images/bg-pattern.png");
    background-size: auto;

    img{
        margin-top: 30%;
        width: 215px;
    }

    h4{
        margin: 0;
        font-family: 'Ultra';
        font-size: 24px;
        font-weight: 400;
        color: $teal;
    }

    p{
        width: 80%;
        margin: 0;
        color: $white;
        font-size: 24px;
        text-align: center;
    }
}