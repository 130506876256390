@import '../../../Styles/variables';

.feedback-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    

    .feedback-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }

    &-correct {
        .feedback-background {
            background-color: $teal;
        }
    }

    &-incorrect {
        .feedback-background {
            background-color: $orange;
        }
    }

    .feedback-text {
        position: absolute;
        bottom: 40%;
        left: 50%;
        transform: translateX(-50%);

        width: 80%;
        height: 200px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 28px;
        -webkit-filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
        filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.1));
        font-size: 24px;
        font-weight: 700;
        text-align: center;

        &-correct {
            border: 6px solid $white;
            background-color: $pink;
            color: white;
        }

        &-incorrect {
            background-color: $white;
            color: $trousers;
        }

        p{
            padding: 0 10%;
        }
    }

    .feedback-img {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
    }

    .feedback-points {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);

        width: fit-content;

        color: white;
        font-family: 'Ultra';
        font-weight: 400;
        font-size: 36px;
    }
}