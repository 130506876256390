@import '../../Styles/variables';

.quiz {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
        margin: 60px 10%;
    }
    
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;

        div {
            margin-bottom: 20px;
        }

        h5 {
            margin: 0 0 20px 0;
        }

        p {
            font-size: 14px;
            font-weight: 400;
        }

        .form-text{
            margin-top: 0;
        }

        .q-mc {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                margin-bottom: 15px;

                &:nth-last-of-type(1){
                    margin-bottom: 0;
                }
            }
        }

        .question {
            color: $trousers;
        }

        .indicator-container {
            color: $grey;

            .indicator{
                margin-right: 2px;
            }

            .indicator-active {
                color: $pink;
            }
        }

        .btn-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}